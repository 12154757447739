// 导出任务
// popExportTask
<template>

    <div class="popExportTask">
        <el-dialog title="导出任务"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="360px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-position="top"
                     :inline="false">
                <el-form-item label="月份范围"
                              prop="test1">
                    <el-date-picker v-model="form.test1"
                                    value-format="yyyy-MM"
                                    type="monthrange"
                                    range-separator="至"
                                    start-placeholder="开始月份"
                                    end-placeholder="结束月份">
                    </el-date-picker>
                </el-form-item>
                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               v-loading="exportLoading"
                               @click="exportFile">保存</el-button>
                </div>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "popExportTask",

    props: {},

    components: {},

    data() {
        return {
            dialogVisible: true,
            exportLoading: false,
            form: {
                test1: "", // 月份范围
            },
            formRules: {},
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 导出任务
        exportFile() {
            // let menuId = this.menuId || window.localStorage.getItem("tabId");
            if (this.form.test1 && this.form.test1.length) {
                this.exportLoading = true;
                this.commonJs
                    .exportTableToExcel(
                        {
                            startMonth: this.form.test1[0],
                            endMonth: this.form.test1[1],
                        },
                        `/workTask/work-task/exportWorkTask`,
                        "导出任务"
                    )
                    .then(() => {
                        this.exportLoading = false;
                    });
            } else {
                this.$message({ message: "请选择月份范围", type: "error" });
            }
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>