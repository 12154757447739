import { render, staticRenderFns } from "./depTask.vue?vue&type=template&id=40fe0b9e&scoped=true&"
import script from "./depTask.vue?vue&type=script&lang=js&"
export * from "./depTask.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fe0b9e",
  null
  
)

export default component.exports