// 全部任务-成员工时视图
<template>

    <div class="comMemberView">

        <!-- 组件 表格上方的控制条：导入 新建 同步 等功能按钮 -->
        <com-table-control-bar :showBtn="['ImportTask', 'NewTask', 'SynchroVacation']"
                               :detailRole="detailRole"
                               @update="update"></com-table-control-bar>

        <div class="finisMember memberList">
            <div class="title timeBox">
                <el-date-picker v-model="searchTime"
                                size="large"
                                type="date"
                                value-format="yyyy-MM-dd"
                                format="yyyy年MM月dd日"
                                @change="update"
                                :clearable="false"
                                placeholder="请选择日期"></el-date-picker>
            </div>
            <div class="warp">
                <el-collapse v-model="finisMemberTab"
                             @change="changeFinisMemberTab">
                    <template v-if="allTaskMemberViewList && allTaskMemberViewList.length > 0">
                        <el-collapse-item :name="idx"
                                          v-for="(item,idx) in allTaskMemberViewList"
                                          :key="idx">
                            <template slot="title">
                                <div class="collapseTitle">
                                    <div class="name">{{item.executorName}}</div>
                                    <div class="count">{{item.workTaskNum}}</div>
                                    <div class="count"
                                         v-if="item.wadTotalHours">补填：{{item.wadTotalHours}}（h）</div>
                                    <div class="speedBox">
                                        <div class="line">
                                            <el-progress :show-text="false"
                                                         :stroke-width="18"
                                                         :percentage="(item.todayTotalHours/allTaskMemberViewList[0].todayTotalHours)*100"></el-progress>
                                        </div>
                                        <div class="time">{{item.todayTotalHours}}（h）</div>
                                    </div>
                                </div>
                            </template>
                            <el-table :data="item.workTaskListVoList"
                                      border
                                      stripe>
                                <el-table-column v-for="col in tableData.columns"
                                                 :prop="col.id"
                                                 :key="col.id"
                                                 :label="col.label"
                                                 align="center"
                                                 :min-width="col.width">
                                </el-table-column>
                                <el-table-column prop="status"
                                                 label="是否完成"
                                                 align="center"
                                                 min-width="100">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.status">是</span>
                                        <span v-else>否</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="id"
                                                 label="操作"
                                                 align="center"
                                                 min-width="100">
                                    <template slot-scope="scope">
                                        <!-- 表格操作组件 -->
                                        <com-table-caozuo :item="scope.row"
                                                          :showBtn="['TaskDetails']"
                                                          :detailRole="detailRole"
                                                          @update="update"></com-table-caozuo>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>
                    </template>
                    <div class="emptyBg"
                         v-else></div>
                </el-collapse>
            </div>
        </div>

        <!-- 新建任务 -->
        <popNewTask v-if="popNewTaskVisible"
                    @close="closepopNewTask"></popNewTask>
        <!-- 批量导入任务 -->
        <pop-import-task v-if="popImportTaskVisible"
                         @close="closepopImportTask"></pop-import-task>
        <!-- 任务详情 -->
        <pop-task-details v-if="popTaskDetailsVisible"
                          @close="closepopTaskDetails"></pop-task-details>

    </div>

</template>

<script>
import ComTableCaozuo from "../../comTableCaozuo";
import ComTableControlBar from "../../comTableControlBar";
import PopTaskDetails from "@/modleComponents/Task/popTaskDetails";
import PopImportTask from "../../popImportTask";
import PopNewTask from "../../popNewTask";
import { Loading } from "element-ui";
import API from "@/api/taskManage.js";
export default {
    name: "comMemberView",

    props: [],

    components: {
        ComTableCaozuo,
        ComTableControlBar,
        PopTaskDetails,
        PopImportTask,
        PopNewTask,
    },

    data() {
        return {
            popNewTaskVisible: false, // 新建任务 弹窗显示
            popImportTaskVisible: false, // 批量导入任务 弹窗显示
            popTaskDetailsVisible: false, // 任务详情 弹窗显示
            finisMemberTab: "", // 工时达标成员 手风琴状态
            notFinisMemberTab: "", // 不达标成员 手风琴状态

            searchTime: "", // 选择的日期

            tableData: {
                columns: [
                    { id: "name", label: "任务名称", width: "100" },
                    { id: "todayHours", label: "今日工时（h）", width: "100" },
                    // TODO V3.24 列表-补填工时
                    { id: "wadHours", label: "补填工时（h）", width: "100" },
                    {
                        id: "currentHours",
                        label: "实际工时（h）",
                        width: "100",
                    },
                    { id: "estimateHours", label: "预估工时", width: "100" },
                ],
            },

            allTaskMemberViewList: [], // 成员工时视图列表
            detailRole: null, // 权限数据
        };
    },

    created() {},

    mounted() {
        this.searchTime = this.commonJs.nowTime();
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            // 获取列表
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".comMemberView"),
            });
            if (!this.searchTime) {
                this.searchTime = this.commonJs.nowTime();
            }
            API.allTaskMemberView({
                searchTime: this.searchTime,
            })
                .then((res) => {
                    loading.close();
                    this.allTaskMemberViewList = res.content.standardMemberList;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 新建任务 打开弹框
        openpopNewTask() {
            this.popNewTaskVisible = true;
        },
        // 新建任务 关闭弹框
        closepopNewTask() {
            this.popNewTaskVisible = false;
        },
        // 导入任务 打开弹框
        openpopImportTask() {
            this.popImportTaskVisible = true;
        },
        // 导入任务 关闭弹框
        closepopImportTask() {
            this.popImportTaskVisible = false;
        },
        // 任务详情 打开弹框
        openpopTaskDetails() {
            this.popTaskDetailsVisible = true;
        },
        // 任务详情 关闭弹框
        closepopTaskDetails() {
            this.popTaskDetailsVisible = false;
        },
        // 工时达标成员
        changeFinisMemberTab() {},
        // 不达标成员
        changeNotFinisMember() {},
    },

    computed: {
        // 权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
    },

    watch: {
        //角色按钮权限
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "全部任务_成员工时视图"
                );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// .comMemberView{

// }
.memberList {
    margin-bottom: 25px;
}
.memberList .title {
    height: 54px;
    padding: 7px 22px;
    background: #9ccdfdba;
    border-radius: 6px 6px 0px 0px;
}
.memberList ::v-deep .el-collapse-item__header {
    position: relative;
}
.memberList ::v-deep .el-collapse-item__header .el-collapse-item__arrow {
    position: absolute;
    left: 10px;
}
.collapseTitle {
    width: 100%;
    overflow: hidden;
    margin: 0 35px;
}
.collapseTitle .name {
    width: 20%;
    float: left;
}
.collapseTitle .count {
    width: 20%;
    float: left;
}
.collapseTitle .speedBox {
    width: 20%;
    float: right;
    position: relative;
}
.collapseTitle .speedBox .line {
    width: 80%;
    position: absolute;
    top: 10px;
}
.collapseTitle .speedBox .time {
    position: absolute;
    width: 20%;
    right: 0;
    text-align: right;
}

// 日期
.timeBox {
    background: #ffffff;
    margin-bottom: 15px;
    border-radius: 8px;
}
.timeBox ::v-deep .el-input__inner {
    padding-left: 35px;
    font-size: 14px;
    font-weight: 400;
    color: #595959;
}
</style>
