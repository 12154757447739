// 部门任务-统计信息
<template>

    <div class="comStatisticalInfo">
        <div class="tabTable blueLine">
            <el-tabs v-model="activeName"
                     @tab-click="handleClick">
                <el-tab-pane label="工时统计视图"
                             name="工时统计视图"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="tabContentBox">
            <!-- 工时统计视图 -->
            <div class="taskView"
                 v-if="activeName === '工时统计视图'">
                <com-hours-view></com-hours-view>
            </div>
        </div>
    </div>

</template>

<script>
import ComHoursView from "./comHoursView";
export default {
    name: "comStatisticalInfo",

    props: [],

    components: {
        ComHoursView,
    },

    data() {
        return {
            activeName: "工时统计视图",
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 切换Tab
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// .comStatisticalInfo{

// }
</style>
