// 部门任务-统计信息-工时统计视图
<template>
    <div class="comHoursView">
        <div class="warp"
             v-if="this.type">
            <div class="timeBox">
                <el-date-picker v-model="time"
                                size="large"
                                :type="type"
                                value-format="yyyy-MM-dd"
                                :picker-options="{ firstDayOfWeek: 1 }"
                                :format="timeFormat"
                                :clearable="false"
                                placeholder="请选择日期"></el-date-picker>
                <div class="flr">
                    <el-radio-group v-model="type">
                        <el-radio-button label="week"
                                         v-role="commonJs.getBtnRoles(detailRole, 'Btn_week')">
                            周
                        </el-radio-button>
                        <el-radio-button label="month"
                                         v-role="commonJs.getBtnRoles(detailRole, 'Btn_month')">
                            月
                        </el-radio-button>
                        <el-radio-button label="year"
                                         v-role="commonJs.getBtnRoles(detailRole, 'Btn_year')">
                            年
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <el-row :gutter="30">
                <el-col :span="8">
                    <div class="dataStatistics blueLine">
                        <el-card shadow="never">
                            <div slot="header">
                                <span>数据统计</span>
                            </div>
                            <el-row :gutter="18">
                                <el-col :span="12"
                                        v-for="(item, idx) in dataStatistics"
                                        :key="idx">
                                    <div class="dataListItem">
                                        <div class="warp">
                                            <div class="img"
                                                 :class="`dataListItemImg${idx + 1}`"></div>
                                            <div class="info">
                                                <div class="title mb10">{{ item.label }}</div>
                                                <div class="value">{{ item.value }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="16">
                    <div class="eachDayHours blueLine"
                         v-if="type !== 'month'">
                        <el-card shadow="never">
                            <div slot="header">
                                <span>每{{ type === 'year' ? '月' : '日' }}工时变化情况</span>
                            </div>
                            <div class="echartBox"
                                 id="eachDayHours"></div>
                        </el-card>
                    </div>
                    <div class="eachDayHours blueLine"
                         v-else>
                        <el-card shadow="never">
                            <div slot="header">
                                <span>每周工时变化情况</span>
                            </div>
                            <div class="echartBox"
                                 id="eachDayHoursMonth"></div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>

            <div class="listBox mt30">
                <el-row :gutter="20"
                        v-for="(item, idx) in personList"
                        :key="idx"
                        class="mb15">
                    <el-col :span="3">{{ item.executorName }}</el-col>
                    <el-col :span="3">{{ item.workTaskNum }}</el-col>
                    <el-col :span="3">提前率：{{ item.advanceRate }}</el-col>
                    <el-col :span="3">效率：{{ item.efficiency }}</el-col>
                    <el-col :span="3">补填工时：{{ item.wadTotalHours }}</el-col>
                    <el-col :span="9">
                        <div class="speedBox">
                            <div class="line"
                                 v-if="(item.todayTotalHours / personList[0].todayTotalHours) * 100">
                                <el-progress :text-inside="true"
                                             :stroke-width="18"
                                             color="#FFB500"
                                             :show-text="false"
                                             :percentage="
                                        (item.todayTotalHours / personList[0].todayTotalHours) * 100
                                    "></el-progress>
                            </div>
                            <div class="time">{{ item.todayTotalHours }}（h）</div>
                        </div>
                    </el-col>
                    <!-- <el-col :span="7"
                            class="posr"
                            style="height: 100%">
                        <ComSpeed :max="personList[0].todayTotalHours"
                                  :total="item.todayTotalHours"
                                  :dataArr="[item.normalTotalHours, item.wadTotalHours]"></ComSpeed>
                    </el-col>
                    <el-col :span="2">
                        {{item.todayTotalHours || 0}}（h）
                    </el-col> -->
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/taskManage.js";
export default {
    name: "comHoursView",

    props: [],

    components: {},

    data() {
        return {
            time: "", // 日期
            type: "year", // 年月日视图切换

            dataStatistics: [], // 数据统计
            eachDayHours: {}, // 每月工时变化情况
            personList: [], // 成员工时列表
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            // 获取列表
            this.allTaskTaskView();
        },
        // 获取列表
        allTaskTaskView() {
            if (!this.type) {
                return;
            }
            let loading = Loading.service({
                target: document.querySelector(".comHoursView"),
            });
            let data = {
                type: this.type, // 类型：year,month,week
                year: "", //年份
                month: "", // 月份
                startTime: "", // 周-日期开始时间
                endTime: "", // 周-日期结束时间
            };
            if (this.type === "year") {
                data.year = this.time.split("-")[0];
            } else if (this.type === "month") {
                data.year = this.time.split("-")[0];
                data.month = this.time.split("-")[1];
            } else if (this.type === "week") {
                data.startTime = this.$moment(this.time)
                    .subtract(1, "days")
                    .format("yyyy-MM-DD");
                data.endTime = this.$moment(data.startTime)
                    .add(6, "days")
                    .format("yyyy-MM-DD");
            }
            API.allTaskTaskView(data)
                .then((res) => {
                    loading.close();
                    // 统计数据
                    this.dataStatistics = [
                        { label: "总工时", value: res.content.totalHours },
                        {
                            label: "补填工时",
                            value: res.content.wadDeptTotalHours,
                        },
                        {
                            label: "完成任务",
                            value: res.content.finishSubTaskNum,
                        },
                        { label: "提前率", value: res.content.advanceRate },
                        { label: "人均工时", value: res.content.meanRate },
                        { label: "参与项目", value: res.content.projectNum },
                    ];
                    // 每月工时变化情况
                    if (this.type !== "month") {
                        this.eachDayHours = res.content.nameValueVoList;
                        let xArr = [],
                            itemArr = [];
                        this.eachDayHours.forEach((item) => {
                            let name = `${item.name}${
                                this.type === "year" ? "月" : "日"
                            }`;
                            xArr.push(name);
                            itemArr.push(item.value);
                        });
                        this.yearWeekView(xArr, itemArr);
                    } else {
                        let xArr = res.content.weeksMap.month;
                        let valAgo = res.content.weeksMap.valAgo;
                        let valAfter = res.content.weeksMap.valAfter;
                        this.monthView(xArr, valAfter, valAgo);
                    }
                    // 成员工时列表
                    this.personList = res.content.deptStatisticsPersonVoList;
                })
                .catch(() => {
                    loading.close();
                });
        },

        // 年周工时变化情况
        yearWeekView(xArr, itemArr) {
            // let that = this;
            let yearWeekView = this.$echarts.init(
                document.getElementById("eachDayHours")
            );
            let option = {
                tooltip: {
                    trigger: "axis",
                    formatter: "{b} : {c}（h）",
                },
                legend: {
                    show: false,
                },
                grid: {
                    top: "15%",
                    bottom: "5%",
                    left: "5%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: xArr,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#ddd",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#ddd"],
                                width: 1,
                                type: "solid",
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#000000",
                                fontSize: "14px",
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        axisTick: {
                            show: false,
                        },
                        type: "value",
                        name: "",
                        // minInterval: 1,
                        // nameRotate: '0.1',
                        // minInterval: 1,
                        nameTextStyle: {
                            //y轴上方单位的颜色
                            color: "#999999",
                            fontSize: "12",
                            fontWeight: "600",
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#ddd",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#ddd"],
                                width: 1,
                                type: "solid",
                            },
                        },
                        axisLabel: {
                            formatter: "{value}（h）",
                            textStyle: {
                                color: "#000000",
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: "bar",
                        data: itemArr,
                        barMaxWidth: 18,
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    1,
                                    0,
                                    0,
                                    [
                                        {
                                            offset: 0,
                                            color: "#4672FF", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "#4BB2FF", // 100% 处的颜色
                                        },
                                    ],
                                    false
                                ),
                                //柱形图圆角，初始化效果
                                barBorderRadius: [15, 15, 15, 15],
                                label: {
                                    show: false, //开启显示
                                    formatter: function (params) {
                                        if (params.value > 0) {
                                            return params.value;
                                        } else {
                                            return " ";
                                        }
                                    }, //显示百分号
                                    position: "top", //在上方显示
                                    textStyle: {
                                        //数值样式
                                        color: "#17C9B8",
                                        fontSize: 14,
                                    },
                                },
                            },
                        },
                    },
                ],
            };
            yearWeekView.setOption(option, true);
            // yearWeekView.on("click", (e) => {
            //     let id = ids[xArr.indexOf(e.name)];
            //     that.$router.push({
            //         name: "TaskTable",
            //         query: {
            //             startTime: that.startTime,
            //             endTime: that.endTime,
            //             depName: id,
            //             ganwei: that.isGanwei(),
            //             chartName: 2,
            //         },
            //     });
            // });
        },
        // 每月工时变化情况
        monthView(xArr, itemArr1, itemArr2) {
            // let that = this;
            let monthView = this.$echarts.init(
                document.getElementById("eachDayHoursMonth")
            );
            let option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            formatter: function (params) {
                                if (params.seriesData.length === 0) {
                                    window.mouseCurValue = params.value;
                                }
                            },
                        },
                    },
                    formatter: function (params) {
                        let res = "",
                            sum = 0;
                        for (let i = 0; i < params.length; i++) {
                            let series = params[i];
                            sum += Number(series.data);
                            if (sum >= window.mouseCurValue) {
                                res =
                                    series.axisValue +
                                    "<br/>" +
                                    series.marker +
                                    series.seriesName +
                                    ":" +
                                    series.data +
                                    "<br/>";
                                break;
                            }
                        }
                        return res;
                    },
                },
                legend: {
                    show: true,
                    orient: "vertical",
                    right: "0",
                    top: "0",
                    data: ["本周", "非本周"],
                },
                grid: {
                    top: "15%",
                    bottom: "5%",
                    left: "5%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: xArr,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#ddd",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#ddd"],
                                width: 1,
                                type: "solid",
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#000000",
                                fontSize: "14px",
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        axisTick: {
                            show: false,
                        },
                        type: "value",
                        name: "",
                        // minInterval: 1,
                        // nameRotate: '0.1',
                        // minInterval: 1,
                        nameTextStyle: {
                            //y轴上方单位的颜色
                            color: "#999999",
                            fontSize: "12",
                            fontWeight: "600",
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#ddd",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#ddd"],
                                width: 1,
                                type: "solid",
                            },
                        },
                        axisLabel: {
                            formatter: "{value}（h）",
                            textStyle: {
                                color: "#000000",
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "本周",
                        type: "bar",
                        stack: "1",
                        data: itemArr1,
                        barMaxWidth: 18,
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    1,
                                    0,
                                    0,
                                    [
                                        {
                                            offset: 0,
                                            color: "#4672FF", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "#4BB2FF", // 100% 处的颜色
                                        },
                                    ],
                                    false
                                ),
                                //柱形图圆角，初始化效果
                                barBorderRadius: [15, 15, 15, 15],
                                label: {
                                    show: false, //开启显示
                                    formatter: function (params) {
                                        if (params.value > 0) {
                                            return params.value;
                                        } else {
                                            return " ";
                                        }
                                    }, //显示百分号
                                    position: "top", //在上方显示
                                    textStyle: {
                                        //数值样式
                                        color: "#17C9B8",
                                        fontSize: 14,
                                    },
                                },
                            },
                        },
                    },
                    {
                        name: "非本周",
                        type: "bar",
                        stack: "1",
                        data: itemArr2,
                        barMaxWidth: 18,
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    1,
                                    0,
                                    0,
                                    [
                                        {
                                            offset: 0,
                                            color: "#FFB500", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "#FDC74F", // 100% 处的颜色
                                        },
                                    ],
                                    false
                                ),
                                //柱形图圆角，初始化效果
                                barBorderRadius: [15, 15, 15, 15],
                                label: {
                                    show: false, //开启显示
                                    formatter: function (params) {
                                        if (params.value > 0) {
                                            return params.value;
                                        } else {
                                            return " ";
                                        }
                                    }, //显示百分号
                                    position: "top", //在上方显示
                                    textStyle: {
                                        //数值样式
                                        color: "#17C9B8",
                                        fontSize: 14,
                                    },
                                },
                            },
                        },
                    },
                ],
            };
            monthView.setOption(option, true);
            // monthView.on("click", (e) => {
            //     let id = ids[xArr.indexOf(e.name)];
            //     that.$router.push({
            //         name: "TaskTable",
            //         query: {
            //             startTime: that.startTime,
            //             endTime: that.endTime,
            //             depName: id,
            //             ganwei: that.isGanwei(),
            //             chartName: 2,
            //         },
            //     });
            // });
        },
    },

    computed: {
        // 时间显示格式
        timeFormat() {
            if (this.type === "year") {
                return "yyyy年";
            } else if (this.type === "month") {
                return "yyyy年MM月";
            } else if (this.type === "week") {
                let startTime = this.$moment(this.time)
                    .subtract(1, "days")
                    .format("yyyy-MM-DD");
                let startTimeFormat = this.$moment(this.time).format(
                    "yyyy年MM月DD日"
                );
                let endTime = this.$moment(startTime)
                    .add(6, "days")
                    .format("yyyy年MM月DD日");
                let weekRange = `${startTimeFormat} ~ ${endTime}`;
                return weekRange;
            } else {
                return "";
            }
        },
        // 权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
    },

    watch: {
        // 切换年月周
        type: {
            handler() {
                this.time = this.$moment()
                    .startOf("isoWeek")
                    .add(1, "days")
                    .format("yyyy-MM-DD");
                this.eachDayHours = null;
                this.update();
            },
            immediate: true,
        },
        // 选择日期
        time() {
            this.update();
        },
        //角色按钮权限
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "统计信息_工时统计视图"
                );
                if (this.commonJs.isShowBtn(this.detailRole, "Btn_year")) {
                    this.type = "year";
                } else if (
                    this.commonJs.isShowBtn(this.detailRole, "Btn_month")
                ) {
                    this.type = "month";
                } else if (
                    this.commonJs.isShowBtn(this.detailRole, "Btn_week")
                ) {
                    this.type = "week";
                } else {
                    this.type = "";
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// 日期
.timeBox {
    background: #ffffff;
    margin-bottom: 30px;
    border-radius: 8px;
    padding: 30px;
    overflow: hidden;
}
.timeBox ::v-deep .el-input__inner {
    padding-left: 35px;
    width: 360px;
    font-size: 14px;
    font-weight: 400;
    color: #595959;
}
.timeBox .flr {
    margin-top: 2px;
}
// 数据统计
.dataListItem {
    height: 90px;
    background: #fbfbfb;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
    margin-bottom: 17px;
    position: relative;
}
.dataListItem .warp {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;
}
.dataListItem .img {
    width: 42px;
    height: 42px;
    display: inline-block;
    background: url("../../../../../assets/imgs/icon_dataListItem1.png")
        no-repeat center center;
}
.dataListItem .img.dataListItemImg2 {
    background-image: url("../../../../../assets/imgs/icon_dataListItem2.png");
}
.dataListItem .img.dataListItemImg3 {
    background-image: url("../../../../../assets/imgs/icon_dataListItem3.png");
}
.dataListItem .img.dataListItemImg4 {
    background-image: url("../../../../../assets/imgs/icon_dataListItem4.png");
}
.dataListItem .img.dataListItemImg5 {
    background-image: url("../../../../../assets/imgs/icon_dataListItem5.png");
}
.dataListItem .img.dataListItemImg6 {
    background-image: url("../../../../../assets/imgs/icon_dataListItem6.png");
}
.dataListItem .info {
    padding-left: 26px;
    width: 150px;
    box-sizing: border-box;
    display: inline-block;
}
.dataListItem .title {
    font-size: 14px;
    font-weight: 400;
    color: #595959;
}
.dataListItem .value {
    font-size: 30px;
    font-family: DIN;
    font-weight: 500;
    color: #222222;
}

// card样式
.comHoursView ::v-deep .el-card__header {
    height: 78px;
    line-height: 78px;
    padding: 0 30px;
    font-size: 18px;
    font-weight: bold;
    color: #262626;
}
// 图表
.echartBox {
    height: 322px;
}
// 进度
.speedBox {
    width: 80%;
    float: right;
    overflow: hidden;
}
.speedBox .line {
    width: 80%;
    float: left;
    transform: translateY(15px);
}
.speedBox .time {
    float: left;
    width: 20%;
    text-align: right;
    padding-left: 5px;
}
// 表格
.listBox {
    padding: 30px;
}
.listBox .el-row {
    height: 48px;
    line-height: 48px;
    text-align: center;
    background: #f7f7f7;
    font-size: 14px;
    font-weight: 400;
    color: #595959;
}
.listBox .el-progress-bar__outer {
    background: none;
}
</style>
