// 全部任务
<template>

    <div class="comAllTask">
        <div class="tabTable blueLine">
            <el-tabs v-model="activeName"
                     @tab-click="handleClick">
                <el-tab-pane label="未完成视图"
                             name="未完成视图"></el-tab-pane>
                <el-tab-pane label="已完成视图"
                             name="已完成视图"></el-tab-pane>
                <el-tab-pane label="成员工时视图"
                             name="成员工时视图"></el-tab-pane>
                <el-tab-pane label="任务工时视图"
                             name="任务工时视图"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="tabContentBox">
            <!-- 未完成视图 -->
            <div class="notDidView"
                 v-if="activeName === '未完成视图'">
                <com-not-did-view></com-not-did-view>
            </div>
            <!-- 已完成视图 -->
            <div class="didView"
                 v-if="activeName === '已完成视图'">
                <com-did-view></com-did-view>
            </div>
            <!-- 成员工时视图 -->
            <div class="memberView"
                 v-if="activeName === '成员工时视图'">
                <com-member-view></com-member-view>
            </div>
            <!-- 任务工时视图 -->
            <div class="taskView"
                 v-if="activeName === '任务工时视图'">
                <com-task-view></com-task-view>
            </div>
        </div>
    </div>

</template>

<script>
import ComTaskView from "./comTaskView";
import ComMemberView from "./comMemberView";
import ComDidView from "./comDidView";
import ComNotDidView from "./comNotDidView";
export default {
    name: "comAllTask",

    props: [],

    components: {
        ComTaskView,
        ComMemberView,
        ComDidView,
        ComNotDidView,
    },

    data() {
        return {
            activeName: "未完成视图",
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 切换Tab
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// .comAllTask{

// }
</style>
