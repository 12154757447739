// 全部任务-任务工时视图
<template>

    <div class="comNotDidView">

        <!-- 组件 表格上方的控制条：导入 新建 同步 等功能按钮 -->
        <com-table-control-bar :showBtn="['ImportTask', 'NewTask']"
                               :detailRole="detailRole"
                               @update="update"></com-table-control-bar>

        <div class="controlBar ofh">
            <el-date-picker v-model="searchForm.searchTime"
                            class="fll"
                            value-format="yyyy-MM-dd"
                            type="date"
                            @change="update"
                            :clearable="false"
                            placeholder="选择日期">
            </el-date-picker>
        </div>

        <div class="listBox">
            <el-table :data="tableData.data"
                      border
                      :height="tableHeight"
                      stripe>
                <el-table-column v-for="col in tableData.columns"
                                 :prop="col.id"
                                 :key="col.id"
                                 :label="col.label"
                                 align="center"
                                 :min-width="col.width">
                    <template slot-scope="scope">
                        <div v-if="col.id === 'projectName'">
                            <span v-if="scope.row.reserveProjectId">
                                {{scope.row[col.id] ? `${scope.row[col.id]}（机会）` : '暂无' }}
                            </span>
                            <span v-else>
                                {{scope.row[col.id] || '暂无' }}
                            </span>
                        </div>
                        <span v-else>
                            {{scope.row[col.id] || '暂无'}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="status"
                                 label="是否完成"
                                 align="center"
                                 min-width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column prop="id"
                                 label="操作"
                                 align="center"
                                 min-width="100">
                    <template slot-scope="scope">
                        <!-- 表格操作组件 -->
                        <com-table-caozuo :item="scope.row"
                                          :showBtn="['TaskDetails', 'Del']"
                                          :detailRole="detailRole"
                                          @update="update"></com-table-caozuo>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination :page="searchForm.page"
                        :limit="searchForm.size"
                        :total="pageTotal"
                        @pagination="paginationChange" />
        </div>

    </div>

</template>

<script>
import ComTableCaozuo from "../../comTableCaozuo";
import ComTableControlBar from "../../comTableControlBar";
import { Loading } from "element-ui";
import API from "@/api/taskManage.js";
import Pagination from "@/components/Pagination"; // 分页

export default {
    name: "comNotDidView",

    props: [],

    components: {
        ComTableCaozuo,
        ComTableControlBar,
        Pagination,
    },

    data() {
        return {
            tableData: {
                data: [],
                columns: [
                    { id: "name", label: "任务名称", width: "100" },
                    { id: "executorName", label: "任务执行人", width: "100" },
                    {
                        id: "projectName",
                        label: "关联项目（项目机会）",
                        width: "100",
                    },
                    {
                        id: "estimateHours",
                        label: "预计工时（h）",
                        width: "100",
                    },
                    {
                        id: "currentHours",
                        label: "实际工时（h）",
                        width: "100",
                    },
                    { id: "todayHours", label: "今日工时（h）", width: "100" },
                    // TODO V3.24 列表-补填工时
                    { id: "wadHours", label: "补填工时（h）", width: "100" },
                ],
            },

            searchForm: {
                searchTime: "", // 时间
                page: 1,
                size: 20,
            },
            pageTotal: 0,
            detailRole: null, // 权限数据
        };
    },

    created() {},

    mounted() {
        this.searchForm.searchTime = this.commonJs.nowTime();
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            // 获取列表
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".listBox"),
            });
            API.allTaskHourView(this.searchForm)
                .then((res) => {
                    loading.close();
                    let data = res.content.records;
                    data.forEach((item) => {
                        item.sonCount = item.allSubTaskNum
                            ? `${item.allSubTaskNum}/${item.finishSubTaskNum}`
                            : 0;
                    });
                    this.tableData.data = data;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.page = e.page;
            this.searchForm.size = e.size;
            this.getList();
        },
    },

    computed: {
        // 权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 420 });
        },
    },

    watch: {
        //角色按钮权限
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "全部任务_任务工时视图"
                );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// .comNotDidView{

// }
</style>
