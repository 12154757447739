// 部门任务-今日任务
<template>

    <div class="comTodayTask">
        <div class="tabTable blueLine">
            <el-tabs v-model="activeName"
                     @tab-click="handleClick">
                <el-tab-pane label="成员工时视图"
                             name="commemberview"></el-tab-pane>
                <el-tab-pane label="任务视图"
                             name="comtaskview"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="tabContentBox">
            <!-- 成员工时视图 commemberview -->
            <div class="memberView"
                 v-if="activeName === 'commemberview'">
                 <com-member-view ref="commemberview"></com-member-view>
            </div>
            <!-- 任务视图 comtaskview -->
            <div class="taskView"
                 v-if="activeName === 'comtaskview'">
                 <com-task-view ref="comtaskview"></com-task-view>
            </div>
        </div>
    </div>

</template>

<script>
import ComTaskView from './comTaskView'
import ComMemberView from './comMemberView'
export default {
    name: "comTodayTask",

    props: [],

    components: {
        ComTaskView,
        ComMemberView,
    },

    data() {
        return {
            activeName: "commemberview",
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 切换Tab
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// .comTodayTask{

// }
</style>
