// 部门任务
<template>

    <div class="depTask baseBg">
        <div class="tabBox">
            <div class="header">
                <el-tabs v-model="activeName"
                         type="border-card"
                         @tab-click="handleClick">
                    <el-tab-pane label="今日任务"
                                 name="今日任务"></el-tab-pane>
                    <el-tab-pane label="全部任务"
                                 name="全部任务"></el-tab-pane>
                    <el-tab-pane label="统计信息"
                                 name="统计信息"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="tabContentBox">
                <!-- 今日任务 -->
                <div class="todayTask"
                     v-if="activeName === '今日任务'">
                    <com-today-task></com-today-task>
                </div>
                <!-- 全部任务 -->
                <div class="allTask"
                     v-if="activeName === '全部任务'">
                    <com-all-task></com-all-task>
                </div>
                <!-- 统计信息 -->
                <div class="statisticalInfo"
                     v-if="activeName === '统计信息'">
                    <com-statistical-info></com-statistical-info>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ComStatisticalInfo from "./components/depTask/comStatisticalInfo/index";
import ComAllTask from "./components/depTask/comAllTask/index";
import ComTodayTask from "./components/depTask/comTodayTask/index";
export default {
    name: "depTask",

    props: [],

    components: {
        ComStatisticalInfo,
        ComAllTask,
        ComTodayTask,
    },

    data() {
        return {
            activeName: "今日任务",
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 切换Tab
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>
