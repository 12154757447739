// 成员工时视图
<template>

    <div class="comMemberView">

        <el-button type="success"
                   class="flr"
                   style="margin: 10px"
                   @click="openpopExportTask">
            导出任务
        </el-button>
        <!-- 组件 表格上方的控制条：导入 新建 同步 等功能按钮 -->
        <com-table-control-bar :showBtn="['ImportTask', 'NewTask', 'SynchroVacation']"
                               :detailRole="detailRole"
                               @update="update"></com-table-control-bar>

        <!-- 工时达标成员 -->
        <div class="finisMember memberList">
            <div class="title">工时达标成员</div>

            <div class="warp">
                <el-collapse v-model="finisMemberTab"
                             @change="changeNotFinisMember">
                    <template v-if="standardMemberList && standardMemberList.length > 0">
                        <el-collapse-item :name="idx"
                                          v-for="(item,idx) in standardMemberList"
                                          :key="idx">
                            <template slot="title">
                                <div class="collapseTitle">
                                    <div class="name">{{item.executorName}}</div>
                                    <div class="count">{{item.workTaskNum}}</div>
                                    <div class="count"
                                         v-if="item.leaveHours">请假：{{item.leaveHours}}（h）</div>
                                    <div class="speedBox">
                                        <div class="line">
                                            <el-progress :show-text="false"
                                                         :stroke-width="18"
                                                         :percentage="(item.todayTotalHours/standardMemberList[0].todayTotalHours)*100"></el-progress>
                                        </div>
                                        <div class="time">{{item.todayTotalHours}}（h）</div>
                                    </div>
                                </div>
                            </template>
                            <el-table :data="item.workTaskListVoList"
                                      border
                                      stripe>
                                <el-table-column v-for="col in tableData.columns"
                                                 :prop="col.id"
                                                 :key="col.id"
                                                 :label="col.label"
                                                 align="center"
                                                 :min-width="col.width">
                                </el-table-column>
                                <el-table-column prop="status"
                                                 label="是否完成"
                                                 align="center"
                                                 min-width="100">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.status">是</span>
                                        <span v-else>否</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="id"
                                                 label="操作"
                                                 align="center"
                                                 min-width="100">
                                    <template slot-scope="scope">
                                        <!-- 表格操作组件 -->
                                        <com-table-caozuo :item="scope.row"
                                                          :detailRole="detailRole"
                                                          :showBtn="['TaskDetails']"
                                                          @update="update"></com-table-caozuo>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>
                    </template>
                    <div class="emptyBg"
                         v-else></div>
                </el-collapse>
            </div>
        </div>

        <!-- 不达标成员 -->
        <div class="notFinisMember memberList">
            <div class="title">不达标成员</div>
            <div class="warp">
                <el-collapse v-model="notFinisMemberTab"
                             @change="changeNotFinisMember">
                    <template v-if="unStandardMemberList && unStandardMemberList.length > 0">
                        <el-collapse-item :name="idx"
                                          v-for="(item,idx) in unStandardMemberList"
                                          :key="idx">
                            <template slot="title">
                                <div class="collapseTitle">
                                    <div class="name">{{item.executorName}}</div>
                                    <div class="count">{{item.workTaskNum}}</div>
                                    <div class="count"
                                         v-if="item.leaveHours">请假：{{item.leaveHours}}（h）</div>
                                    <div class="speedBox">
                                        <div class="line">
                                            <el-progress :show-text="false"
                                                         :stroke-width="18"
                                                         :percentage="(item.todayTotalHours/unStandardMemberList[0].todayTotalHours)*100"></el-progress>
                                        </div>
                                        <div class="time">{{item.todayTotalHours}}（h）</div>
                                    </div>
                                </div>
                            </template>
                            <el-table :data="item.workTaskListVoList"
                                      border
                                      stripe>
                                <el-table-column v-for="col in tableData.columns"
                                                 :prop="col.id"
                                                 :key="col.id"
                                                 :label="col.label"
                                                 align="center"
                                                 :min-width="col.width">
                                </el-table-column>
                                <el-table-column prop="status"
                                                 label="是否完成"
                                                 align="center"
                                                 min-width="100">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.status">是</span>
                                        <span v-else>否</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="id"
                                                 label="操作"
                                                 align="center"
                                                 min-width="100">
                                    <template slot-scope="scope">
                                        <!-- 表格操作组件 -->
                                        <com-table-caozuo :item="scope.row"
                                                          :detailRole="detailRole"
                                                          :showBtn="['TaskDetails']"
                                                          @update="update"></com-table-caozuo>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>
                    </template>
                    <div class="emptyBg"
                         v-else></div>
                </el-collapse>
            </div>
        </div>

        <!-- 新建任务 -->
        <popNewTask v-if="popNewTaskVisible"
                    @close="closepopNewTask"></popNewTask>
        <!-- 批量导入任务 -->
        <pop-import-task v-if="popImportTaskVisible"
                         @close="closepopImportTask"></pop-import-task>
        <!-- 任务详情 -->
        <pop-task-details v-if="popTaskDetailsVisible"
                          :id="taskDetailsId"
                          @close="closepopTaskDetails"></pop-task-details>
        <!-- 导出工资 -->
        <PopExportTask v-if="popExportTaskVisible"
                       @close="closepopExportTask"></PopExportTask>
    </div>

</template>

<script>
import PopExportTask from "../popExportTask";
import ComTableCaozuo from "../../comTableCaozuo";
import ComTableControlBar from "../../comTableControlBar";
import PopTaskDetails from "@/modleComponents/Task/popTaskDetails";
import PopImportTask from "../../popImportTask";
import PopNewTask from "../../popNewTask";
import { Loading } from "element-ui";
import API from "@/api/taskManage.js";
export default {
    name: "comMemberView",

    props: [],

    components: {
        PopExportTask,
        ComTableCaozuo,
        ComTableControlBar,
        PopTaskDetails,
        PopImportTask,
        PopNewTask,
    },

    data() {
        return {
            popNewTaskVisible: false, // 新建任务 弹窗显示
            popImportTaskVisible: false, // 批量导入任务 弹窗显示
            popTaskDetailsVisible: false, // 任务详情 弹窗显示
            finisMemberTab: "", // 工时达标成员 手风琴状态
            notFinisMemberTab: "", // 不达标成员 手风琴状态

            tableData: {
                data: [],
                columns: [
                    { id: "name", label: "任务名称", width: "100" },
                    { id: "todayHours", label: "今日工时（h）", width: "100" },
                    { id: "wadHours", label: "补填工时（h）", width: "100" },
                    { id: "currentHours", label: "总工时（h）", width: "100" },
                    { id: "estimateHours", label: "预估工时", width: "100" },
                ],
            },
            standardMemberList: [], // 工时达标成员
            unStandardMemberList: [], // 工时未达标成员
            taskDetailsId: "", // 任务详情查询用id
            detailRole: null, // 权限数据
            popExportTaskVisible: false, // 导出工资弹窗显示
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".comMemberView"),
            });
            API.todayMemberView({
                order: "DESC",
            })
                .then((res) => {
                    loading.close();
                    this.standardMemberList = res.content.standardMemberList; // 工时达标成员
                    this.unStandardMemberList =
                        res.content.unStandardMemberList; // 工时未达标成员
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 新建任务 打开弹框
        openpopNewTask() {
            this.popNewTaskVisible = true;
        },
        // 新建任务 关闭弹框
        closepopNewTask() {
            this.popNewTaskVisible = false;
        },
        // 导入任务 打开弹框
        openpopImportTask() {
            this.popImportTaskVisible = true;
        },
        // 导入任务 关闭弹框
        closepopImportTask() {
            this.popImportTaskVisible = false;
        },
        // 任务详情 打开弹框
        openpopTaskDetails(item) {
            this.taskDetailsId = item.id;
            this.popTaskDetailsVisible = true;
        },
        // 任务详情 关闭弹框
        closepopTaskDetails() {
            this.taskDetailsId = "";
            this.popTaskDetailsVisible = false;
        },
        // 工时达标成员
        changeFinisMemberTab() {},
        // 不达标成员
        changeNotFinisMember() {},
        // 导出工资 打开弹框
        openpopExportTask() {
            this.popExportTaskVisible = true;
        },
        // 导出工资 关闭弹框
        closepopExportTask() {
            this.popExportTaskVisible = false;
        },
    },

    computed: {
        // 权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
    },

    watch: {
        //角色按钮权限
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "今日任务_成员工时视图"
                );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// .comMemberView{

// }
.memberList {
    margin-bottom: 25px;
}
.memberList .title {
    height: 54px;
    padding: 17px 22px;
    background: #9ccdfdba;
    border-radius: 6px 6px 0px 0px;
}
.memberList ::v-deep .el-collapse-item__header {
    position: relative;
}
.memberList ::v-deep .el-collapse-item__header .el-collapse-item__arrow {
    position: absolute;
    left: 10px;
}
.collapseTitle {
    width: 100%;
    overflow: hidden;
    margin: 0 35px;
}
.collapseTitle .name {
    width: 20%;
    float: left;
}
.collapseTitle .count {
    width: 20%;
    float: left;
}
.collapseTitle .speedBox {
    width: 20%;
    float: right;
    position: relative;
}
.collapseTitle .speedBox .line {
    width: 80%;
    position: absolute;
    top: 10px;
}
.collapseTitle .speedBox .time {
    position: absolute;
    width: 20%;
    right: 0;
    text-align: right;
}
</style>
